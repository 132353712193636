<!--能源模块--设备卡片组件-->
<template>
  <div id="distribution" @click="goDetails" :title="cardContent.name">
    <!--<div>
      <img :src="getImages" />
    </div>
    <div class="title">
      <div class="title-name">{{ cardContent.name }}</div>
    </div>
     <div>
      <span class="pv">{{ cardContent.firDisPointPv }}</span>
      <span class="unit">{{ cardContent.firDisPointText }}</span>
    </div> -->
    <div class="title-box">
        <img :src="getImages" />
        <div class="title" :title="cardContent.name">{{ cardContent.name }}</div>
    </div>

    <div style="position: relative;">
        <div v-if="cardContent.firDisPointId>0" :title="cardContent.firDisPointName">
            <div v-if="cardContent.firDisPointType==3 || cardContent.firDisPointType==4 || cardContent.firDisPointType==5" >
                <span class="pv">{{ cardContent.firDisPointText }}</span>
            </div>
            <div v-else-if="cardContent.firDisPointType==13 || cardContent.firDisPointType==14 || cardContent.firDisPointType==19">
                <span class="pv">{{ cardContent.firDisPointText }}</span>
            </div>
            <div v-else>
                <span class="pv">{{ cardContent.firDisPointPv }}</span>
                <span class="unit">{{ cardContent.firDisPointText }}</span>
            </div>

        </div>
        <div v-if="cardContent.secDisPointId>0" :title="cardContent.secDisPointName">
            <div v-if="cardContent.secDisPointType==3 || cardContent.secDisPointType==4 || cardContent.secDisPointType==5" >
                <span class="pv">{{ cardContent.secDisPointText }}</span>
            </div>
            <div v-else-if="cardContent.secDisPointType==13 || cardContent.secDisPointType==14 || cardContent.secDisPointType==19">
                <span class="pv">{{ cardContent.secDisPointText }}</span>
            </div>
            <div v-else>
                <span class="pv">{{ cardContent.secDisPointPv }}</span>
                <span class="unit">{{ cardContent.secDisPointText }}</span>
            </div>

        </div>

        <div class="v-btn">
          <!-- <a-button  type="info" icon="video-camera" shape="round" size="small" @click.stop @click="getVideo(1003)"/> -->
          <a-button v-if="cardContent.cameraId1>0" type="info" icon="video-camera" shape="round" size="small" @click.stop @click="getVideo(cardContent.cameraId1)"/>
          <a-button v-if="cardContent.cameraId2>0" type="info" icon="video-camera" shape="round" size="small" @click.stop @click="getVideo(cardContent.cameraId2)"/>
        </div >
    </div>

  </div>
</template>

<script>

export default {
  name: "distribution",
  props: {
    cardContent: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      changeState: false,
    };
  },
  methods: {
    // 根据卡片类型跳转对应的详情页
    goDetails() {
      let type = this.cardContent.iconNo;
      console.log('iconNo',this.cardContent.iconNo)
      if(type === 420){//
        let eqmid =  this.cardContent.id;
        this.$router.push({
            path: "/homepage/facility/facilityDetails",
            query: {
              id: eqmid,
              iconNo:type
            },
        });
      }else{
        let eqmid =  this.cardContent.id;
        this.$router.push({
            path: "/homepage/energy/energyEquipmentDetails",
            query: {
              id: eqmid,
              iconNo:type
            },
        });
      }
      
    },
    getVideo(cameraId) {
      const data = {
        vdialog: true,
        cameraId: cameraId,
        camera: {
          clientId: this.$store.getters.clientId,
          sitegrId: this.$store.getters.sitegrId,
          siteId: this.$route.query.id,
          cameraId: cameraId,
        }
      }
      console.log('getVideo>>>',data);
      this.$emit('hasVideoChange', data );
    },
  },
  computed: {
    // 卡片图标根据对应iocnNO加载对应图标
    getImages: function () {
      let iconNo = this.cardContent.iconNo.toString();
      return require("../../../../public/images/energy/" + iconNo + ".png");
    },
  },
};
</script>

<style scoped>
#distribution {
  width: 200px;
  height: 110px;
  background-color: #f2f4f7;
  border-radius: 3px;
  margin-bottom: 15px;
  border: 1px solid #ffffff;
  cursor: pointer;
  transition: 200ms;
  float: left;
}
#distribution:hover {
  border: 1px solid #7682ce;
}
.title-box{
  display: flex;
  justify-content: flex-start;
}
.title{
    height: 48px;
    line-height: 48px;
    font-size: 14px;
   
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.title-name{
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.pv {
  font-size: 18px;
  padding-left: 10px;
  font-family: "Microsoft YaHei";
 
}
.unit{
 font-size: 11px; 
 font-family: "Microsoft YaHei";
}
.v-btn{
	  position: absolute;
	  right: 2%;
	  bottom: 1%;
	  display: grid;
	}
</style>